@import "~/node_modules/bootstrap/scss/bootstrap";
@import "_options";

body {
  font-family: $serif-font;
  color: $dark;
  a {
    &:hover {
      color: $red;
    }
  }
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: $sans-serif-font;
}

h1 {
  font-weight: 800;
  text-transform: uppercase;
  color: $dark;
  line-height: 1em;
}

h1 {
  /* Extra small devices (phones, less than 768px) */
  font-size: 40px;

  /* Small devices (tablets, 768px and up) */
  @media (min-width: 768px) {
    font-size: 4em;
  }
}

h1,
h2 {
  text-transform: uppercase;
}

h2,
h3 {
  font-weight: 600;
}

h4 {
  padding: 15px 0;
}

p {
  font-size: 1.1em;
}

small {
  font-size: 0.7em;
}

.float-right {
  float: right;
}

.container {
  max-width: $container-max-width;
}

.bg-pink {
  background-color: $pink;
  a {
    color: $beige;
  }
}

.bg-grey {
  background-color: $grey;
}

.bg-yellow {
  background-color: $yellow;

  a {
    color: $red;
  }
}

.bg-dark {
  background-color: $dark;
  color: $white;
  a {
    color: $red;
  }
}

.bg-blue {
  background-color: $blue;
  a {
    color: $red;
  }
}

.bg-red {
  background-color: $red;
  color: $white;
}

header {
  background-color: $blue;
  a,
  .navbar-light .navbar-nav .nav-link {
    color: $black !important;
    text-decoration: underline;
    font-size: 1em;
  }

  .collapse.show {
    text-align: right;
  }

  .navbar-nav {
    margin-left: auto;
    a,
    .navbar-light .navbar-nav .nav-link {
      color: $black !important;
      text-decoration: underline;
      font-size: 1em;
    }

    .collapse.show {
      text-align: right;
    }

    .navbar-nav {
      margin-left: auto;
    }
  }
}

.btn {
  padding: 15px;
  font-size: 1.1em;

  &.dark {
    background-color: $black;
    border-color: $black;
    color: $white;
    &:hover {
      background-color: $red;
    }
  }
  &.blue {
    background-color: $blue;

    &:hover {
      background-color: $red;
      color: $white !important;
    }
  }

  &.red {
    background-color: $red;
    border-color: $red;
    color: $white;
    &:hover {
      border-color: $blue;
      background-color: $blue;
    }
  }
}

.dog-intro {
  @media (max-width: 768px) {
    display: none;
  }
}

section {
  img {
    max-width: 100%;
    max-height: 600px;

    &.fill-space {
      height: 100%;
      width: 100%;
      text-align: center;
      overflow: hidden;
    }
  }
  overflow: hidden;
}

#members {
  .member {
    margin-bottom: 25px;
    p {
      font-size: 0.9em;
    }
    img {
      text-align: center;
      width: 100%;
      height: auto;
    }
  }
}

#contact-details {
  h3 {
    text-transform: uppercase !important;
  }
}

#map {
  max-width: 100% !important;
  max-height: 100% !important;
}

#hours {
  p {
    margin-bottom: 0px;
  }
}
